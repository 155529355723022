<template>
	<div>
		<label>{{ $t('ordonnance.donner_un_nom') }}* :</label>
		<div class="form-group">
			<input type="text" class="form-control" v-model="document_name" :placeholder="$t('ordonnance.donner_un_nom')" required>
		</div>

		<label>{{ $t('ordonnance.document_date') }}* :</label>
		<div class="form-group">
			<e-datepicker v-model="document_date" required />
		</div>

        <label>{{ $t('ordonnance.choisir_document') }}</label>
        <div>
            <b-form-file
                    v-model="document_file"
                    :placeholder="$t('media.choisir_document')"
                    :drop-placeholder="$t('fichier.drop_file')+'...'"
            ></b-form-file>
            <div v-show="(document_file && isValidSizeFile) || document_filename" class="mt-3">{{ $t('fichier.selected_file') }}: {{ document_file ? document_file.name : document_filename }}</div>
		</div>

		<ButtonValidation @btn-onclick="valid_form" icon="suivant" />
	</div>
</template>

<script type="text/javascript">
    import Documents from "@/mixins/Documents.js"
    import Shutter from '@/mixins/Shutter.js'

    export default {
        name: "OrdonnanceEdit",
        mixins: [Documents, Shutter],
        props: [
            'document_id',
            'horse_id'
        ],
        components: {
            ButtonValidation: () => import('GroomyRoot/components/Buttons/Validation')
        },
        data () {
            return {
                document_limit_size: '10', // Mo
                document_file: null,
                document_filename: null,
                document_name: '',
                document_date: null,
                actual_date: new Date()
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.document = await this.loadDocument(this.document_id)
                this.document_date = Date.parseTz(this.document.document_date)
                this.document_name = this.document.document_name
                this.document_filename = this.document.document_filename
            },

            async valid_form() {
                let params = {
                    document_name: this.document_name,
                    document_date: Date.parseTz(this.document_date),
                }
                if(this.document_file) {
                    const filename = await this.$sync.uploadFile(this.document_file)
                     params = {
                        document_name: this.document_name,
                        document_date: Date.parseTz(this.document_date),
                        document_filename: filename
                    }
                }
                
				await this.updateDocument(params, this.document_id);
				this.successToast()
				this.ok()
            }
        },
        computed: {
 			isValidSizeFile() {
                const file_size = this.document_file.size         // Taille en octets
                const limit = this.document_limit_size * 1000000  // conversion en octets
                return file_size < limit
            }
		},
    }
</script>
